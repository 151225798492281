import React from 'react';
import styles from './CardWrapper.module.less';

function CardWrapper({ value, title, content, onClick = () => {}, ...args }) {
  return (
      <button type="button" className={styles.container} onClick={onClick} {...args}>
        <label>{title}</label>
        <p>{value}</p>
        <div style={{ marginTop: 10 }}>{content}</div>
      </button>
  );
}

export default CardWrapper;
