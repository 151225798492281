import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Col, Menu, PageHeader, Popover, Row, Spin, Modal } from 'antd';
import { Route, Routes, useNavigate, matchPath, useLocation } from 'react-router-dom';
import { BellOutlined, CloseOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import useRoutes from './useRoutes';
import styles from './BasicLayout.module.less';
import { clearPrincipal } from '../data/reducers/authReducer';
import AuthAside from '../components/auth/Aside';
import classNames from 'classnames/bind';
import { restApi } from '../apis';

const cx = classNames.bind(styles);

const childRenderer = (page, parent) => {
  const args = {
    path: [parent, page.path].filter((v) => !!v).join('/'),
  };
  if (page.children?.length) {
    // , args.path
    args.path += '/*';
    args.children = page.children?.map((child) => childRenderer(child));
  }
  if (page.screen) {
    const Elem = page.screen;
    args.element = <Elem />;
  }
  return <Route key={page.path} {...args} />;
};

function BasicLayout({ loading }) {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { routes, pages, notLoginPages } = useRoutes();
  const [sideOpen, setSideOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = React.useMemo(
    () => pages.find(({ path }) => matchPath(path, location.pathname)),
    [pages, location],
  );
  const currentRoute = ['/' + location.pathname.split('/').splice(0, 2).join('')];
  const [openKeys, setOpenKeys] = useState(currentRoute);
  const handleLogout = async () => {
    if (!window.confirm('로그아웃 하시겠습니까?')) {
      return;
    }
    localStorage.clear();

    restApi.defaults.headers.common.authorization = null;
    await dispatch(clearPrincipal());
    navigate('/login');
  };

  const clickMenu = React.useCallback(
    (item) => {
      if (item?.key === '/') return navigate(item.key);
      // if (principal) {
      //   if (!principal.writeInfo) {
      //     Modal.info({
      //       title: '상점 등록',
      //       content: '상점 등록 후 이용 가능합니다.',
      //       width: 600,
      //       closable: true,
      //       centered: true,
      //       onOk: () => {
      //         navigate('/store/modify');
      //       },
      //     });
      //     return;
      //   }

      // }
      navigate(item.key);
    },
    [principal, navigate],
  );

  React.useEffect(() => {
    if (loading) return;
    if (!principal && location?.pathname === '/') {
      navigate('/login');
    }

    if (principal && location?.pathname === '/login') {
      navigate('/');
    }

    if (!principal && !notLoginPages.some(({ path }) => path === location?.pathname)) {
      navigate('/login');
    }
  }, [loading, principal, navigate]);

  // console.log('location::: ', location.pathname);

  return (
    <div className={cx({ basic_layout: true, auth: !principal || loading, side_open: sideOpen })}>
      <aside>
        <div className={styles.intro_bg} />
        <div className={styles.bi}>
          <img style={{ cursor: 'pointer' }} onClick={() => navigate('/')} src="/assets/logo_white.png" alt="logo" />
          {!!principal && (
            <Button
              onClick={() => setSideOpen((x) => !x)}
              type="text"
              icon={<CloseOutlined style={{ color: '#fff' }} />}
            />
          )}
        </div>
        {!principal ? (
          <div className={styles.center_contents}>
            <Helmet>
              <title>홈그릿 MD</title>
            </Helmet>
            <AuthAside />
          </div>
        ) : (
          <div className={styles.aside_body}>
            <Helmet>
              <title>{`홈그릿 MD | ${currentPage?.title}`}</title>
            </Helmet>
            <nav className={styles.menu}>
              <Menu
                // onSelect={console.log}
                onClick={clickMenu}
                style={{ width: '100%' }}
                theme="dark"
                mode="inline"
                defaultOpenKeys={openKeys}
                selectedKeys={[location.pathname]}
              >
                {routes?.map((route) => {
                  const Icon = route?.icon;
                  if (!route.children?.length) {
                    return (
                      <Menu.Item key={route.path} icon={Icon ? <Icon /> : null}>
                        {route?.title}
                      </Menu.Item>
                    );
                  }
                  const items = route?.children ?? [];

                  const grouped = items.reduce((a, c) => {
                    const group = c.group?.replace(/\s+/g, '_') || '_';
                    if (!a[group]) a[group] = [];
                    a[group].push(c);
                    return a;
                  }, {});

                  const keys = Object.keys(grouped).filter((v) => v !== '_');

                  return (
                    <Menu.SubMenu key={route.path} icon={Icon ? <Icon /> : null} title={route.title}>
                      {keys.map((key) => (
                        <Menu.ItemGroup key={key} title={key.replace(/\_/g, ' ')}>
                          {grouped[key].map((menu) => (
                            <Menu.Item key={menu.path}>{menu.title}</Menu.Item>
                          ))}
                        </Menu.ItemGroup>
                      ))}
                      {grouped['_'].map((child) => (
                        <Menu.Item key={child.path}>{child.title}</Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </nav>
          </div>
        )}
      </aside>

      {loading ? (
        <div className={styles.auth_body}>
          <Spin />
        </div>
      ) : (
        <>
          {!principal ? (
            <div className={styles.auth_body}>
              <Routes>{notLoginPages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
            </div>
          ) : (
            <div className={styles.body}>
              <header>
                <Row gutter={5}>
                  <Col className={styles.menu_button}>
                    <Button onClick={() => setSideOpen((x) => !x)} shape="circle" icon={<MenuOutlined />} />
                  </Col>

                  <Col flex="1" />
                  <Popover
                    placement="bottomRight" //pmk수정사항: 로그아웃 말풍선 위치 오른쪽 아래로 변경
                    trigger="click"
                    content={
                      <Button type="text" onClick={handleLogout}>
                        로그아웃
                      </Button>
                    }
                  >
                    <Col>
                      <span style={{ marginRight: '15px' }}>안녕하세요 {principal?.userId} 님!</span>
                      {/* pmk수정사항: localStorage에 담은 userName property를 헤더 오른쪽 상단에 출력 */}
                      <Button shape="circle" icon={<UserOutlined />} />
                    </Col>
                  </Popover>
                </Row>
              </header>
              <main>
                <PageHeader title={currentPage?.title} />
                <Routes>{pages?.filter((v) => !!v.screen)?.map((page) => childRenderer(page, undefined))}</Routes>
              </main>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BasicLayout;
