import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Row, Col, Form, Table, DatePicker } from 'antd';
import useFetch from '#hooks/useFetch';
import moment from 'moment';

const AdStatusType = {
  submit: 'SUBMIT',
  on: 'ADON',
  off: 'ADOFF',
};

const DateType = {
  start: 'START',
  end: 'END',
  create: 'CREATE',
};

function AdvertiseHistories({ areaCode, storeId, categories, areaGroup }) {
  const URL = `/md/advertisement/area/${areaCode}/${storeId}`;

  const initialParams = React.useMemo(
    () => ({
      adStatusType: null,
      dateType: DateType.start,
      startAt: null,
      endAt: null,
      category: null,
      page: 0,
      limit: 10,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);

  const tableRef = React.useRef();

  const tableFetcher = useFetch();

  const fetchTableData = (params) => {
    tableFetcher.fetch(restApi.get(URL, { params })).catch(handleError);
  };

  React.useEffect(() => {
    fetchTableData(params);
  }, []);

  const handleReset = () => {
    setParams(initialParams);
    fetchTableData(initialParams);
    return initialParams;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form onFinish={() => fetchTableData(params)} onReset={handleReset}>
        <span>{areaGroup ? `${areaGroup.area1} > ${areaGroup.area2}` : null}</span>
        <Row gutter={10} style={{ marginTop: 15, marginBottom: 15, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.category}
              placeholder="카테고리"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.category = value;
                  }),
                );
              }}
              options={categories.options ? categories.options : []}
              style={{ width: 150 }}
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.adStatusType}
              placeholder="광고 상태 옵션"
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.adStatusType = value;
                  }),
                );
              }}
              options={[
                { label: '광고 중지', value: AdStatusType.off },
                { label: '광고 진행중', value: AdStatusType.on },
                { label: '광고 신청중', value: AdStatusType.submit },
              ]}
              style={{ width: 150 }}
              allowClear
            />
          </Col>
          <Col>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '시작일', value: DateType.start },
                  { label: '종료일', value: DateType.end },
                  { label: '광고신청일', value: DateType.create },
                ]}
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    const [startAt, endAt] = e;
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={tableFetcher?.data?.results || []}
        ref={tableRef}
        columns={[
          {
            title: '카테고리',
            dataIndex: 'category',
            width: 100,
            render: (value) => (value ? categories.map[value] : '-'),
          },

          {
            title: '기간',
            dataIndex: 'duration',
            width: 100,
            render: (_, data) => `${moment(data.endAt).diff(moment(data.startAt), 'days')}일`,
          },
          {
            title: '시작일',
            dataIndex: 'startAt',
            width: 100,
            render: (value) => value,
          },
          {
            title: '종료일',
            dataIndex: 'endAt',
            width: 100,
            render: (endAt) => endAt,
          },
          {
            title: '광고신청일',
            dataIndex: 'createdAt',
            width: 100,
            render: (createdAt) => createdAt,
          },
          {
            title: '상태',
            dataIndex: 'adStatusType',
            width: 100,
            render: (adStatusType) => {
              switch (adStatusType) {
                case AdStatusType.submit:
                  return '신청중';
                case AdStatusType.on:
                  return '광고중';
                case AdStatusType.off:
                  return '광고 중지';
                default:
                  return;
              }
            },
          },
        ]}
        scroll={{ x: 1000 }}
        pagination={{
          position: ['bottomCenter'],
          total: tableFetcher?.data?.total,
          pageSize: tableFetcher?.data?.limit ?? 10,
          current: tableFetcher?.data?.pageCur + 1,
          showSizeChanger: false,
          onChange: async (p) => {
            setParams(
              produce((draft) => {
                draft.page = p - 1;
              }),
            );
            tableFetcher.fetch(
              restApi.get(URL, {
                params: { ...params, page: p - 1 },
              }),
            );
          },
        }}
      />
    </Space>
  );
}

export default AdvertiseHistories;
