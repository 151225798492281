import React from 'react';
import { Button, Checkbox, Col, Row, Select, Space, Typography } from 'antd';
import { LIMIT } from '../../common/define';
import qs from 'query-string';
import useQuery from '../../hooks/useQuery';
import { downloadExcel } from '../../utils/downloadExcel';

const CommTableHeader = ({
  paging,
  excel,
  searchset,
  toggleObj = null,
  rightBts,
  onAdd,
  dataSource,
  title,
  ObjToChangeKey,
}) => {
  const { query, navigate, location } = useQuery();
  const path = location.pathname;
  const selectOptions = [1, 5, 10, 20, 50, 100];
  //path로 쿼리값 받아온 후, 그 값에 맞춰서 select box 옵션사항 변경
  const onChangeLimit = (value) => {
    const args = {
      ...query,
      limit: value,
      page: 0,
    };
    navigate('?' + qs.stringify(args), { replace: true });
  };

  const onToggle = (event) => {
    const args = {
      ...query,
      [toggleObj?.key]: event.target?.checked || null,
    };
    navigate('?' + qs.stringify(args), { replace: true });
  };

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Space>
          <Typography.Text>
            전체{' '}
            <Typography.Text style={{ color: '#4c72f1' }}>
              {paging?.total ? Number(paging?.total).toLocaleString() : 0}
            </Typography.Text>{' '}
            {!!paging?.total && `중 \t`}
            {!!paging?.total && typeof paging?.pageCur === 'number' && typeof paging?.limit === 'number' && (
              <Typography.Text style={{ color: '#4c72f1' }}>
                {Number(paging?.pageCur * paging?.limit + 1).toLocaleString()} -{' '}
                {Number(paging?.pageCur * paging?.limit + paging?.offset)}
              </Typography.Text>
            )}
          </Typography.Text>
          {searchset && ( //엑셀다운 버튼용
            <Button style={{ marginRight: 5 }}>조회항목 설정</Button>
          )}
        </Space>
      </Col>
      <Col>
        <Space>
          {onAdd && (
            <Button
              type={'primary'}
              onClick={() => {
                onAdd();
              }}
            >
              등록
            </Button>
          )}
          {toggleObj && (
            <Checkbox checked={query?.[toggleObj.key]} onChange={onToggle}>
              {toggleObj.text}
            </Checkbox>
          )}
          {rightBts}
          {excel && ( //엑셀다운 버튼용
            <Button
              disabled={!(dataSource?.length > 0)}
              style={{ marginRight: 5 }}
              onClick={() => {
                downloadExcel({
                  title: title,
                  dataJsonArray: dataSource,
                  ObjToChangeKey: ObjToChangeKey,
                  // options: form?.inputOptions,
                  isExclusive: 'EXCLUSIVE',
                });
              }}
            >
              엑셀 다운
            </Button>
          )}
          <Select
            value={String(query?.limit || LIMIT)}
            style={{ width: 120, marginLeft: 5 }}
            onChange={onChangeLimit}
            options={selectOptions.map((value) => ({ label: `${String(value)}개`, value: String(value) }))}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default CommTableHeader;

