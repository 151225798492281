import React from 'react';
import styles from './Aside.module.less';

function AuthAside() {
  return (
    <>
      <h2 className={styles.h2}>홈그릿MD는?</h2>
      <p className={styles.p}>
        고객을 위한 탁월한 선택,
        <br />
        홈그릿에서 도와드립니다.
        <br />
        지금 고객을 위한 새로운 채널, 홈그릿으로 시작하세요!
      </p>
    </>
  );
}

export default AuthAside;
