import React from 'react';
import { Divider, Row, Col, Space, Button, Select, DatePicker, Input } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import { useReduxStore } from '#hooks/useReduxStore';
import produce from 'immer';
import moment from 'moment';
import useIndex from '#hooks/useIndex';
import useGetPartners from '../../../hooks/useGetPartners';
import useCheckSearchTypeAndKeyword from '../../../hooks/useCheckSearchTypeAndKeyword';

function InquireStore() {
  const { tree, map } = useReduxStore('codes');
  const { getIndex } = useIndex();
  const [dataSource, setDataSource] = React.useState([]);
  const initailParams = React.useMemo(
    () => ({
      dateType: 'CREATED_AT',
      startAt: null,
      endAt: null,
      storeQuestionTypeCode: null,
      searchType: null,
      keyword: null,
      storeId: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);
  const { partners } = useGetPartners();
  const [onClickSubmit] = useCheckSearchTypeAndKeyword(params.searchType, params.keyword);
  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '등록일', value: 'CREATED_AT' },
                  { label: '답변일', value: 'ANSWER_AT' },
                ]}
                placeholder="날짜옵션"
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    const [startAt, endAt] = e;
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Select
                placeholder="파트너명"
                style={{ width: 120 }}
                value={params.storeId}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.storeId = value;
                    }),
                  );
                }}
                allowClear
                options={partners}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                allowClear
                options={[
                  { label: '문의내용', value: 'CONTENT' },
                  { label: '파트너명', value: 'STORE_NAME' },
                  { label: '회원아이디', value: 'USER_ID' },
                  { label: '회원 이름', value: 'USER_NAME' },
                  { label: '상점 아이디', value: 'STORE_USERID' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300, paddingBlock: '6px' }}
                value={params.keyword}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button onClick={onClickSubmit} type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/md/store-questions"
        columns={[
          {
            title: '번호',
            dataIndex: 'orderNum',
            key: 'orderNum',
            width: 100,
            render: (value, obj, index) => getIndex(index),
          },
          {
            title: '파트너명',
            dataIndex: 'storeName',
            key: 'storeName',
            width: 200,
            render: (storeName) => storeName || '-',
          },
          {
            title: '상점아이디',
            dataIndex: 'storeUserId',
            key: 'storeUserId',
            width: 200,
            render: (storeName) => storeName || '-',
          },
          {
            title: '문의내용',
            dataIndex: 'contents',
            key: 'contents',
            width: 200,
            render: (contents, obj) => <Link to={`/manage/store/inquire/${obj.id}`}>{contents || '-'}</Link>,
          },
          {
            title: '첨부파일',
            dataIndex: 'isAttach',
            key: 'isAttach',
            width: 200,
            render: (isAttach) => (isAttach ? 'O' : 'X'),
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 200,
            render: (userId) => userId || '-',
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 200,
            render: (userName) => userName || '-',
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200,
            render: (createdAt) => (createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm') : '-'),
          },
          {
            title: '답변여부',
            dataIndex: 'answerStateCode',
            key: 'answerStateCode',
            width: 200,
            render: (answerStateCode) => map?.[answerStateCode] || '-',
          },
          {
            title: '답변일',
            dataIndex: 'answerAt',
            key: 'answerAt',
            width: 200,
            render: (answerAt) => (answerAt ? moment(answerAt).format('YYYY-MM-DD HH:mm') : '-'),
          },
        ]}
        scroll={{ x: 1120 }}
        onLoadData={({ results }) => {
          setDataSource(results);
        }}
      />
    </div>
  );
}

export default InquireStore;
