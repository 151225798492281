import React from 'react';
import { Button, Space, Descriptions } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '#hooks/useReduxStore';
import FileUpload from '#components/datalist/FileUpload';
import moment from 'moment';

const cx = classNames.bind(styles);

function InquireStoreDetail() {
  const navigate = useNavigate();
  const { inquireId } = useParams();
  const [inquireInfo, setInquireInfo] = React.useState(null);

  const init = React.useCallback(() => {
    restApi
      .get(`/md/store-questions/${inquireId}`)
      .then(({ data }) => {
        setInquireInfo(data);
      })
      .catch(handleError);
  }, [inquireId]);

  React.useEffect(() => {
    if (!inquireId) return;
    init();
  }, [inquireId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너명">
            {inquireInfo?.storeName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquireInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquireInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="등록일">
            {inquireInfo?.createdAt ? moment(inquireInfo?.createdAt).format('YYYY-MM-DD / HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquireInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="첨부파일">
            {inquireInfo?.images.length ? (
              <Space direction="vertical">
                <FileUpload items={inquireInfo?.images[0].imageId} disabled listType="picture-card" hideUpload />
              </Space>
            ) : (
              'X'
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {inquireInfo?.answer}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquireInfo?.answerAt ? moment(inquireInfo?.answerAt).format('YYYY-MM-DD / HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquireInfo?.storeUserId} / {inquireInfo?.adminName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default InquireStoreDetail;
