import produce from 'immer';
import moment from 'moment';
import React from 'react';
import { Divider, Select, Button, Modal, Input, DatePicker, Row, Col, Space, Form, Radio, Checkbox } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import formatComma from '#utils/formatComma';
import CommTable from '#components/table/CommTable';
import SearchForm from '#components/SearchForm';
import ProductDetails from '#components/App/ProductDetails';
import useGetPartners from '../../../hooks/useGetPartners';

function GoodsItemsIndex() {
  const categoriesTree = useSelector((selector) => selector.categories?.product?.tree, shallowEqual);
  const { map: codesMap, tree: codesTree } = useSelector((selector) => selector.codes, shallowEqual);
  const tableRef = React.useRef();

  const saleStateCodesOptions = React.useMemo(
    () => codesTree?.PSS?.items?.map(({ code, label }) => ({ label, value: code })) ?? [],
    [codesTree],
  );

  const INIT_PARAMS = React.useMemo(
    () => ({
      dateType: 'CREATED_AT', //상품 조회 초기 날짜옵션 등록일 기준으로 세팅
      startAt: null, //상품조회 초기값 1달로 세팅
      endAt: null,
      saleStateCodes: null, //상품조회 - 판매상태 초기값으로 판매중 statecode 세팅
      productName: '',
      productId: '',
      businessName: '',
      categoryCode: null,
      category1: null,
      category2: null,
      category3: null,
      category4: null,
      manufacturer: null,
      isDisplay: null,
      storeId: null,
    }),
    [saleStateCodesOptions],
  );
  const [params, setParams] = React.useState(INIT_PARAMS);
  const { partners } = useGetPartners();
  const isAllSaleStateCodes = React.useMemo(() => {
    if (params.saleStateCodes) {
      if (params.saleStateCodes.length === 0) {
        return true;
      } else {
        return saleStateCodesOptions.length === params.saleStateCodes.length;
      }
    } else {
      return true;
    }
  }, [saleStateCodesOptions, params.saleStateCodes]);
  const [originDataSource, setOriginDataSource] = React.useState([]);
  const [dataSource, setDataSource] = React.useState([]);
  const categoryOptions = React.useMemo(
    () =>
      [...Array(4)].reduce(
        (p, _, i) => {
          if (params[`category${i}`]) {
            const c = p[i - 1].find(({ value }) => value === params[`category${i}`]);
            p.push(c.items.map(({ code, label, items }) => ({ label, value: code, items })));
          }
          return p;
        },
        [categoriesTree?.map(({ code, label, items }) => ({ label, value: code, items }))],
      ),
    [categoriesTree, params],
  );
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(INIT_PARAMS);
          return INIT_PARAMS;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                placeholder="날짜 옵션"
                options={[
                  { label: '등록일', value: 'CREATED_AT' },
                  { label: '최종 수정일', value: 'UPDATED_AT' },
                ]}
                style={{ width: 120 }}
              />
              <DatePicker.RangePicker
                disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    const [startAt, endAt] = e;
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Radio.Group
                // disabled={!params.dateType}
                value={moment(params.endAt).diff(params.startAt, 'day')}
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.startAt = moment().subtract(event.target.value, 'day').format('YYYY-MM-DD');
                      draft.endAt = moment().format('YYYY-MM-DD');
                    }),
                  );
                }}
                options={[
                  { label: '오늘', value: 0 },
                  { label: '일주일', value: 7 },
                  { label: '1개월', value: 30 },
                  { label: '3개월', value: 90 },
                ]}
                optionType="button"
                buttonStyle="solid"
              />
            </Space>
          </Col>
          <Col>
            <Form.Item label="판매상태">
              <Space>
                <Checkbox
                  checked={isAllSaleStateCodes}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.saleStateCodes = saleStateCodesOptions.map(({ value }) => value);
                      }),
                    );
                  }}
                >
                  전체
                </Checkbox>
                {saleStateCodesOptions?.map((option) => {
                  const isChecked = params.saleStateCodes?.includes(option.value);

                  return (
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      checked={isAllSaleStateCodes || isChecked} //판매상태에서 전체값이 체크되면 다 체크되도록 로직 변경
                      onChange={(event) => {
                        setParams(
                          produce((draft) => {
                            if (isAllSaleStateCodes) {
                              draft.saleStateCodes = [event.target.value];
                            } else if (isChecked) {
                              draft.saleStateCodes = Array.isArray(draft.saleStateCodes)
                                ? draft.saleStateCodes.filter((value) => value !== event.target.value)
                                : [];
                            } else {
                              Array.isArray(draft.saleStateCodes)
                                ? draft.saleStateCodes.push(event.target.value)
                                : (draft.saleStateCodes = [draft.saleStateCodes, event.target.value]);
                            }
                          }),
                        );
                      }}
                    >
                      {option.label}
                    </Checkbox>
                  );
                })}
              </Space>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="전시상태">
              <Space>
                <Checkbox
                  checked={params.isDisplay === null}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = null;
                      }),
                    );
                  }}
                >
                  전체
                </Checkbox>
                <Checkbox
                  checked={params.isDisplay === null || params.isDisplay === true || params.isDisplay === 'true'}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = true;
                      }),
                    );
                  }}
                >
                  전시중
                </Checkbox>
                <Checkbox
                  checked={params.isDisplay === null || params.isDisplay === false || params.isDisplay === 'false'}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.isDisplay = false;
                      }),
                    );
                  }}
                >
                  전시중지
                </Checkbox>
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Space>
                <Select
                  placeholder="파트너명"
                  style={{ width: 120 }}
                  value={params.storeId}
                  onChange={(value) => {
                    setParams(
                      produce((draft) => {
                        draft.storeId = value;
                      }),
                    );
                  }}
                  allowClear
                  options={partners || []}
                />
                {[...Array(4)].map((_, index, array) => {
                  return (
                    <Select
                      style={{ width: 160 }}
                      value={params[`category${index + 1}`] ? params[`category${index + 1}`] : null}
                      allowClear
                      onChange={(value) => {
                        setParams(
                          produce((draft) => {
                            draft[`category${index + 1}`] = value;
                            if (value) {
                              draft.categoryCode = value;
                            } else if (!value) {
                              if (index > 0) {
                                draft.categoryCode = params[`category${index}`];
                              } else {
                                draft.categoryCode = null;
                              }
                            }
                            for (let i = 0; i < array.length; i++) {
                              if (i > index) {
                                draft[`category${i + 1}`] = null;
                              }
                            }
                          }),
                        );
                      }}
                      disabled={!categoryOptions[index]}
                      options={categoryOptions[index]}
                      placeholder={`카테고리${index + 1}`}
                    />
                  );
                })}
              </Space>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Space>
              <Space style={{ marginRight: 50 }}>
                <div style={{ width: '80px' }}>상품명</div>
                <Input
                  value={params.productName}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.productName = event.target.value;
                      }),
                    );
                  }}
                  placeholder="키워드를 입력하세요."
                />
              </Space>
              <Space>
                <div style={{ width: '80px' }}>상품번호</div>
                <Input
                  value={params.productId}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.productId = event.target.value;
                      }),
                    );
                  }}
                  placeholder="키워드를 입력하세요."
                />
              </Space>
            </Space>
          </Col>

          <Col span={24}>
            <Space>
              <Space style={{ marginRight: 50 }}>
                <div style={{ width: '80px' }}>브랜드명</div>
                <Input
                  value={params.businessName}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.businessName = event.target.value;
                      }),
                    );
                  }}
                  placeholder="키워드를 입력하세요."
                />
              </Space>
              <Space>
                <div style={{ width: '80px' }}>제조사명</div>
                <Input
                  value={params.manufacturer}
                  onChange={(event) => {
                    setParams(
                      produce((draft) => {
                        draft.manufacturer = event.target.value;
                      }),
                    );
                  }}
                  placeholder="키워드를 입력하세요."
                />
              </Space>
            </Space>
          </Col>

          <Col span={24}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    검색
                  </Button>
                  <Button htmlType="reset">초기화</Button>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
      </SearchForm>
      <CommTable
        ref={tableRef}
        url="/md/products"
        excel="true"
        // renderHeader={}
        columns={[
          {
            title: '파트너명',
            dataIndex: 'partnerName',
            fixed: 'left',
            width: 120,
          },
          {
            title: '상품번호',
            dataIndex: 'productId',
            fixed: 'left',
            width: 120,
            render: (value) => (
              <Button
                type="primary"
                style={{
                  position: 'relative',
                  left: '-3px',
                  background: 'none',
                  border: 'none',
                  color: '#4c72f1',
                  boxShadow: 'none',
                }}
                onClick={() => {
                  Modal.info({
                    title: '상세설명',
                    width: 550,
                    content: <ProductDetails productId={value} />,
                    // centered: true,
                    closable: true,
                  });
                }}
              >
                {value}
              </Button>
            ),
          },
          {
            title: '상품명',
            dataIndex: 'productName',
            align: 'left',
            width: 300,
            ellipsis: true,
          },
          {
            title: '견적의뢰',
            dataIndex: 'isQuotation',
            width: 80,
            render: (value) => (value ? 'Y' : 'N'),
          },
          {
            title: '판매상태',
            dataIndex: 'saleState',
            width: 80,
            render: (value) => value,
          },
          {
            title: '전시상태',
            dataIndex: 'isDisplay',
            width: 120,
            render: (value) => (value ? '전시중' : '전시중지'),
          },
          {
            title: '재고수량',
            dataIndex: 'count',
            width: 80,
          },
          {
            title: '판매가',
            dataIndex: 'price',
            width: 120,
            render: (value, __, index) => {
              const isChanged = originDataSource[index]?.price !== value;
              if (isChanged) {
                return <b className="changed">{formatComma(value)}</b>;
              }
              return formatComma(value);
            },
          },
          {
            title: '할인가',
            dataIndex: 'discountAmount',
            width: 120,
            render: (value) => formatComma(value),
          },
          {
            title: '옵션',
            dataIndex: 'isOption',
            width: 80,
            render: (value) => (value ? 'Y' : 'N'),
          },
          {
            title: '배송비유형',
            dataIndex: 'deliveryFreeType',
            width: 120,
          },
          {
            title: '배송비결제방식',
            dataIndex: 'deliveryPaymentType',
            width: 120,
            render: (value) => value || '-',
          },
          {
            title: '기본배송비',
            dataIndex: 'deliveryAmount',
            width: 100,
            render: (value) => `${formatComma(value)}원`,
          },
          {
            title: '반품배송비',
            dataIndex: 'refundDeliveryAmount',
            width: 100,
            render: (value) => `${formatComma(value)}원`,
          },
          {
            title: '교환배송비',
            dataIndex: 'changeDeliveryAmount',
            width: 100,
            render: (value) => `${formatComma(value)}원`,
          },
        ]}
        scroll={{ x: 1920 }}
        rowKey={({ productId }) => productId}
        dataSource={dataSource}
        onLoadData={({ results }) => {
          setOriginDataSource(results);
          setDataSource(results);
        }}
      />
    </Space>
  );
}

export default GoodsItemsIndex;
