import React from 'react';
import styles from './CardWrappers.module.less';

const  CardWrapper2 = ({data, content, onClick = () => {}, ...args}) =>{
  return (
      <div className={styles.container}
           {...args}>
        {data.map(row =>
            <div className={styles.wrapper}>
              <div className={styles.box}>
                <label>{row.title || '\u00A0'}</label>
                <p>{row.value}</p>
                <div style={{marginTop: 10}}>{row.content}</div>
              </div>
            </div>,
        )}
      </div>
  );
}




export default CardWrapper2;
