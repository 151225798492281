import * as XLSX from 'xlsx';
import { mapKeys } from 'lodash';

const DefaultOptions = ['옵션가', '재고수량', '사용여부'];

export function downloadExcel({ title, dataJsonArray, ObjToChangeKey, options, isExclusive }) {
  // key 변경해주는 help function
  const objMapper = (obj) => {
    let newObj = {};
    Object.keys(obj)
      .filter((v) => ObjToChangeKey[v])
      .map((v) => (newObj[ObjToChangeKey[v]] = obj[v]));

    if (isExclusive) return newObj;
    const hasSequenceObj = {};

    for (let y = 0; y < 3; y++) {
      if (options[y]) {
        hasSequenceObj[options[y].name] = newObj[options[y].name];
      } else {
        hasSequenceObj[`선택${y + 1}`] = null;
      }
    }

    DefaultOptions.forEach((option) => {
      hasSequenceObj[option] = newObj[option];
    });

    return hasSequenceObj;
  };

  let arrJSON = dataJsonArray.map((data) => {
    return objMapper(data);
  });
  // 엑셀 시트 순서와 값 조정
  let arrJSONChangeValue = [];
  let arrJSONChangeOrder = [];
  let excelHeaderEl = [];

  for (let i = 0; i < arrJSON.length; i++) {
    for (let changeArr in arrJSON[i]) {
      if (changeArr == '견적의뢰' || changeArr == '옵션') {
        excelHeaderEl.push([changeArr, arrJSON[i][changeArr] == true ? 'Y' : 'N']);
      } else if (changeArr == '전시상태') {
        excelHeaderEl.push([changeArr, arrJSON[i][changeArr] == true ? '전시중' : '전시중지']);
      } else if (changeArr == '파트너명') {
        excelHeaderEl.unshift([changeArr, arrJSON[i][changeArr]]);
      } else {
        excelHeaderEl.push([changeArr, arrJSON[i][changeArr]]);
      }
    }
    arrJSONChangeValue.push(excelHeaderEl);
    excelHeaderEl = [];
  }
  for (let i = 0; i < arrJSONChangeValue.length; i++) {
    let arr = {};
    for (let j = 0; j < arrJSONChangeValue[i].length; j++) {
      arr[arrJSONChangeValue[i][j][0]] = arrJSONChangeValue[i][j][1];
    }
    arrJSONChangeOrder.push(arr);
    arr = {};
  }

  // book이라는 게 아예 excel 파일하나라고 생각하면 편함
  let wb = XLSX.utils.book_new();
  let arrJsonToSheet = JSON.parse(JSON.stringify(arrJSONChangeOrder));
  let ws = XLSX.utils.json_to_sheet(arrJsonToSheet);
  wb.Props = {
    Title: title,
    Subject: 'Excel',
    Author: 'Master',
    CreatedDate: new Date(),
  };

  wb.SheetNames.push(title);

  // sheet를 추가 하고 싶을 때 아래 메서드 사용하세요
  // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // 타이틀에 해당하는 sheet에 work sheet 붙여주기.
  wb.Sheets[title] = ws;

  // 해당 부분으로 excel download
  XLSX.writeFile(wb, `${title}.xlsx`);
}
