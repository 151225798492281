import React from 'react';
import { Input } from 'antd';
import formatComma from '../../utils/formatComma';

function InputMoney({ value, onChange, min, inputRef, onMin = () => {}, max, onMax = () => {}, ...props }) {
  return (
    <Input
      ref={inputRef}
      value={value ? (value === '-' ? value : formatComma(value)) : value === 0 ? value : ''}
      onChange={(event) => {
        if (event.target.value === '-') {
          onChange(event.target.value);
        }

        const _value = Number(event.target.value?.replace(/,/g, ''));
        if (isNaN(_value)) {
          return;
        }
        if (min && _value < min) {
          onMin();
          return;
        }
        if (typeof max === 'number' && _value && _value > max) {
          onMax();
          return;
        }
        onChange(_value);
      }}
      {...props}
    />
  );
}

export default InputMoney;
