import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { restApi } from '../../../apis';
import handleError from '#utils/handleError';
import { Button, Descriptions, Space } from 'antd';
import FileUpload from '#components/datalist/FileUpload';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { shallowEqual, useSelector } from 'react-redux';

const cx = classNames.bind(styles);

const RequestEstimateDetail = () => {
  const { estimateId } = useParams();
  const navigate = useNavigate();
  const [estimateInfo, setEstimateInfo] = React.useState(null);
  const categories = useSelector((s) => s.categories, shallowEqual);

  const init = React.useCallback(() => {
    restApi
      .get(`/md/product-estimates/${estimateId}`)
      .then(({ data }) => {
        setEstimateInfo(data);
      })
      .catch(handleError);
  }, [estimateId]);

  React.useEffect(() => {
    if (!estimateId) return;
    init();
  }, [estimateId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="카테고리">
            {categories?.product.map[estimateInfo?.categoryCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            {estimateInfo?.productName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {estimateInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {estimateInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="의뢰내용">
            {estimateInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="사진">
            {estimateInfo?.images.length > 0 ? (
              <Space direction="vertical">
                <FileUpload items={estimateInfo?.images} disabled listType="picture-card" hideUpload />
              </Space>
            ) : (
              'X'
            )}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {estimateInfo?.answer || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {estimateInfo?.answerAt || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {estimateInfo?.adminUserId && `${estimateInfo?.adminUserId} / `}
            {estimateInfo?.adminUserName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
};

export default RequestEstimateDetail;
