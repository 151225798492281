import React from 'react';
import { Button, Space, Descriptions, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import handleError from '#utils/handleError';
import { useReduxStore } from '#hooks/useReduxStore';
import moment from 'moment';
import ProductDetails from '#components/App/ProductDetails';

const cx = classNames.bind(styles);

function ReviewGoodsDetail() {
  const { map: codeMap } = useReduxStore('codes');
  const navigate = useNavigate();
  const { inquireId } = useParams();

  const [inquireInfo, setInquireInfo] = React.useState(null);

  const init = React.useCallback(() => {
    restApi
      .get(`/md/product-questions/${inquireId}`)
      .then(({ data }) => {
        setInquireInfo(data);
      })
      .catch(handleError);
  }, [inquireId]);

  React.useEffect(() => {
    if (!inquireId) return;
    init();
  }, [inquireId, init]);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="파트너명">
            {inquireInfo?.storeName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의분야">
            {codeMap?.[inquireInfo?.typeCode]}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="상품명">
            {inquireInfo?.productName}
            {!!inquireInfo?.productId && (
              <Button
                onClick={() => {
                  Modal.info({
                    title: '상세설명',
                    width: 550,
                    content: <ProductDetails productId={inquireInfo.productId} />,
                    centered: true,
                    closable: true,
                  });
                }}
                style={{ marginLeft: 20 }}
              >
                상품정보
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="주문번호">
            {inquireInfo?.orderProductNumber ? inquireInfo?.orderProductNumber : '-'}
          </Descriptions.Item>

          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원아이디">
            {inquireInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="회원이름">
            {inquireInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="문의내용">
            {inquireInfo?.contents}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="공개여부">
            {typeof inquireInfo?.isSecret === 'boolean' && (inquireInfo?.isSecret ? '비공개' : '공개')}
            {inquireInfo?.isSecret && inquireInfo?.adminName && inquireInfo?.secretAt && (
              <span>
                ({inquireInfo?.adminName} / {inquireInfo?.secretAt})
              </span>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변내용">
            {inquireInfo?.answer ? inquireInfo?.answer : ''}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변일">
            {inquireInfo?.answerAt ? moment(inquireInfo?.answerAt).format('YYYY-MM-DD / HH:mm:ss') : ''}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="답변자">
            {inquireInfo?.adminId ? `${inquireInfo?.adminId} / ` : ''} {inquireInfo?.adminName}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate(-1)}>목록</Button>
      </div>
    </div>
  );
}

export default ReviewGoodsDetail;
