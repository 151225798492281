import { useMemo } from 'react';
import {
  UserOutlined,
  FundFilled,
  GiftOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  SmileTwoTone,
  BarChartOutlined,
} from '@ant-design/icons';
import HomeIndex from '#pages';
import Join from '#pages/user/join';

// user
import Login from '#pages/user';
import FindId from '#pages/user/find_id';
import FindPassword from '#pages/user/find_pw';

//manage
import ManageRequestEstimate from '#pages/manage/request_estimate';
import ManageRequestEstimateDetail from '#pages/manage/request_estimate/detail';
import ManageAccountInfo from '#pages/manage/account';
import ManageAccountDetail from '#pages/manage/account/detail';
import ManageItemsIndex from '#pages/manage/items';
import ManageInquireGoods from '#pages/manage/goods';
import ManageInquireGoodsDetail from '#pages/manage/goods/detail';
import ManageInquireStore from '#pages/manage/store';
import ManageInquireStoreDetail from '#pages/manage/store/detail';
import ManageAdvertise from '#pages/manage/ad';
import ManageCreateAdvertisement from '#pages/manage/ad/create';

// statistics
import DailyPaymentAmount from '#pages/statistics/daily_payment_amount';
import PayersAndPaymentsPerDay from '#pages/statistics/payers_payments_per_day';
import PaymentAmountByDayOfTheWeek from '#pages/statistics/payment_amount_by_day_of_the_week';
import AveragePaymentAmountByDayOfTheWeek from '#pages/statistics/average_payment_amount_by_day_of_the_week';
import DailyRefundRate from '#pages/statistics/daily_refund_rate';

//calculate
import Calculate from '#pages/calculate';

//myInfo
import myInfo from '#pages/myInfo';
import myInfoModity from '#pages/myInfo/modify';

// notice
import Notice from '#pages/notice';
import NoticeDetails from '#pages/notice/detail';

export const notLoginPages = [
  {
    path: '/login',
    title: '로그인',
    screen: Login,
  },
  {
    path: '/join',
    title: '회원가입',
    screen: Join,
  },

  {
    path: '/find_id',
    title: '아이디 찾기',
    screen: FindId,
  },
  {
    path: '/find_pw',
    title: '비밀번호 재설정',
    screen: FindPassword,
  },
];

export const pages = [
  {
    path: '/',
    title: 'MD 메인',
    icon: FundFilled,
    screen: HomeIndex,
    isMenu: true,
  },
  {
    path: '/manage',
    title: '파트너 관리',
    icon: DollarCircleOutlined,
    isMenu: true,
  },
  {
    path: '/manage/account',
    title: '파트너 정보',
    isMenu: true,
    screen: ManageAccountInfo,
  },
  {
    path: '/manage/account/detail/:storeId',
    title: '파트너 상세 정보',
    screen: ManageAccountDetail,
  },
  {
    path: '/manage/goods-info',
    title: '상품 정보',
    isMenu: true,
    screen: ManageItemsIndex,
  },
  {
    path: '/manage/store',
    title: '상점 문의',
    isMenu: true,
    screen: ManageInquireStore,
  },
  {
    path: '/manage/store/inquire/:inquireId',
    title: '상점 문의 상세',
    screen: ManageInquireStoreDetail,
  },
  {
    path: '/manage/goods',
    title: '상품 문의',
    isMenu: true,
    screen: ManageInquireGoods,
  },
  {
    path: '/manage/goods/inquire/:inquireId',
    title: '상품 문의 상세',
    screen: ManageInquireGoodsDetail,
  },
  {
    path: '/manage/request_estimate',
    title: '견적의뢰관리',
    screen: ManageRequestEstimate,
    isMenu: true,
  },
  {
    path: '/manage/request_estimate/:estimateId',
    title: '견적의뢰관리 상세',
    screen: ManageRequestEstimateDetail,
  },
  {
    path: '/manage/advertise',
    title: '광고 현황',
    isMenu: true,
    icon: SmileTwoTone,
    screen: ManageAdvertise,
  },
  {
    path: '/manage/advertise/create',
    title: '검색지역광고 등록',
    screen: ManageCreateAdvertisement,
  },
  {
    path: '/statistics',
    title: '통계',
    icon: BarChartOutlined,
    isMenu: true,
  },
  {
    path: '/statistics/daily_payment_amount',
    title: '일별 결제금액',
    isMenu: true,
    screen: DailyPaymentAmount,
  },
  {
    path: '/statistics/payers_payments_per_day',
    title: '일별 결제자수 및 결제수',
    isMenu: true,
    screen: PayersAndPaymentsPerDay,
  },
  {
    path: '/statistics/payment_amount_by_day_of_the_week',
    title: '요일별 결제금액',
    isMenu: true,
    screen: PaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/average_payment_amount_by_day_of_the_week',
    title: '요일별 평균 결제금액',
    isMenu: true,
    screen: AveragePaymentAmountByDayOfTheWeek,
  },
  {
    path: '/statistics/daily_refun_rate',
    title: '일별 환불율',
    isMenu: true,
    screen: DailyRefundRate,
  },
  {
    path: '/calculate',
    title: '정산관리',
    screen: Calculate,
    isMenu: true,
    icon: GiftOutlined,
  },

  {
    path: '/myInfo',
    title: '내 정보',
    screen: myInfo,
    isMenu: true,
    icon: UserOutlined,
  },
  {
    path: '/myInfo/modify',
    title: '내 정보 수정',
    screen: myInfoModity,
    isMenu: false,
  },
  {
    path: '/notice',
    title: '공지사항',
    isMenu: true,
    icon: ShareAltOutlined,
    screen: Notice,
  },
  {
    path: '/notice/:noticeId',
    title: '공지사항 상세',
    screen: NoticeDetails,
  },
];

export const useRoutes = () => {
  const routes = useMemo(
    () =>
      pages
        ?.filter((v) => !!v.isMenu || !!v.isSubMenu)
        ?.reduce((output, page) => {
          const path = page.path?.substring(1);
          const parentPath = `/${path?.substring(0, path.indexOf('/'))}`;
          const parentIndex = path?.indexOf('/') >= 0 ? output.findIndex((v) => v.path === parentPath) : undefined;
          if (parentIndex === undefined) {
            output.push({
              path: page.path,
              title: page.title,
              icon: page?.icon,
            });
          } else {
            if (!output[parentIndex].children) output[parentIndex].children = [];
            output[parentIndex].children.push({
              path: page.path,
              title: page.title,
              group: page?.group,
            });
          }
          return output;
        }, []),
    [pages],
  );

  return { routes, pages, notLoginPages };
};

export default useRoutes;
