import { Select, Space } from 'antd';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { restApi } from '#apis';
import Chart from 'react-apexcharts';
import produce from 'immer';
import useGetPartners from '../../../hooks/useGetPartners';
import moment from 'moment';

const AveragePaymentAmountByDayOfTheWeek = () => {
  const [params, setParams] = React.useState({});
  const { partners } = useGetPartners();
  const { data, isLoading, error, refetch } = useQuery(
    ['average_payment_amount_by_day_of_the_week', params.storeId],
    async () => {
      const { data } = await restApi.get(`/md/statistics/day-of-week/amount/avg`, {
        params: {
          ...params,
        },
      });
      return data;
    },
    {
      refetchInterval: 1000,
    },
  );
  return (
    <Space style={{ width: '100%', maxWidth: 800 }} direction="vertical">
      <Select
        placeholder="파트너명"
        style={{ width: 120 }}
        value={params.storeId}
        onChange={(value) => {
          setParams(
            produce((draft) => {
              if (value === undefined) {
                draft.storeId = null;
              } else {
                draft.storeId = value;
              }
            }),
          );
          refetch();
        }}
        options={partners || []}
        allowClear
      />
      <Chart
        options={{
          // chart: {
          //   id: 'basic-bar',
          // },
          xaxis: {
            categories: ['월', '화', '수', '목', '금', '토', '일'],
            labels: {
              show: true,
            },
          },
        }}
        series={[
          {
            name: '평균결제금액',
            data: [
              data?.monAmount,
              data?.tueAmount,
              data?.wedAmount,
              data?.thrAmount,
              data?.friAmount,
              data?.satAmount,
              data?.sunAmount,
            ],
          },
        ]}
        type="bar"
        width="800"
      />
    </Space>
  );
};

export default AveragePaymentAmountByDayOfTheWeek;
