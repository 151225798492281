import CardWrapperDimensional from '#components/common/CardWrapperDimensional';
import React from 'react';
import { Divider, Modal, Spin } from 'antd';
import CardWrappers from '#components/common/CardWrappers';
import formatComma from '#utils/formatComma';
import styles from './Page.module.less';
import classNames from 'classnames/bind';
import handleError from '#utils/handleError';
import { restApi } from '../apis';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useReduxStore } from '../hooks/useReduxStore';

const dashboard = {
  sail : {
    label : '매출',
    child : [
      {title : '오늘', key : 'today', },
      {title : '최근 7일', key : 'week', },
    ]

  },
  estimate: {
    label : '견적의뢰',
    child : [
      {title : '오늘', key : 'today', },
      {title : '최근 7일', key : 'week', },
    ]
  },
  calc : {
    label: '정산',
    child : [
      {title : '총 정산가능금액(예치금)', key : 'available', },
      {title : '정산신청금액(최근 7일)', key : 'requested', },
      {title : '정산처리금액(최근 7일)', key : 'completed', },
    ]
  }

}

const cx = classNames.bind(styles);

function HomeIndex() {
  const [dashboardInfo, setDashboardInfo] = React.useState(null);
  const { principal } = useReduxStore('auth');
  const navigate = useNavigate();
  const today = React.useMemo(() => moment().format('YYYY-MM-DD'), []);

  const dayBeforeSeven = React.useMemo(() => moment().subtract(7, 'd').format('YYYY-MM-DD'), []);

  const handleNavigate = React.useCallback(
    (url, params) => () => {
      if (params) {
        navigate(`${url}?${qs.stringify(params)}`);
        return;
      }

      navigate(`${url}`);
    },
    [navigate],
  );

  const requestData = React.useCallback(() => {
    restApi
      .get('/md/dashboard')
      .then(({ data }) => {
        setDashboardInfo(data);
      })
      .catch((e) => {
        handleError(e);
      });
  }, []);

  React.useEffect(() => {
    if (!requestData) return;
    requestData();
    const intervalId = setInterval(requestData, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [requestData]);

  if (!dashboardInfo) return <Spin />;

  return (
    <div>
      <div className={cx({ dashboard_section: true })}>
        {Object.entries(dashboard).map(
            ([parentKey,category],index) => (
                <>

                <div style={{ margin: 10 }}>
                  <label>{category.label}</label>
                  <div style={{  display: 'flex', marginLeft: -5 }}>
                    <CardWrappers
                        data={
                          category.child.map(({title,key}) =>({
                            title, value: formatComma(dashboardInfo?.[parentKey]?.[key])
                          }))
                        }
                    />
                    <CardWrapperDimensional data={
                        dashboardInfo?.[parentKey]?.stores.reduce((a,store)=>{
                            category.child.forEach(({title,key},i) => {
                                const obj = {value : formatComma(store[key])};
                                if(i===0){
                                  obj.title = store.storeName;
                                }
                              if(a.length===i){
                                a.push([obj])
                              }else {
                                a[i].push(obj);
                              }
                            });
                            return a;
                        },[])
                    }/>
                  </div>

                </div>
                {(index>=0&&index<Object.entries(dashboard).length-1)&&<Divider />}
                </>

            )
        )}
      </div>
    </div>
  );
}

export default HomeIndex;
