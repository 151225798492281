import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const useGetPartners = () => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    let partners_ = [];
    principal.storeInfos.map((storeInfo) => {
      partners_.push({ value: String(storeInfo.storeId), label: storeInfo.storeName });
    });
    setPartners(partners_);
  }, []);

  return { partners };
};

export default useGetPartners;
