import React from 'react';
import { Space, Table } from 'antd';
import useTable from '../../hooks/useTable';
import CommTableHeader from './CommTableHeader';
import usePagination from '../../hooks/usePagination';
import { useMemo } from 'react';
import useQuery from '../../hooks/useQuery';
const CommTable = React.forwardRef(
  (
    {
      url,
      params,
      columns,
      scroll,
      customPagination,
      onAdd,
      onFetch,
      toggleObj,
      renderHeader,
      onLoadData,
      onChangePagination,
      rightBts,
      excel,
      ...args
    },
    ref,
  ) => {
    const path = window.location.pathname.split('/');
    const title = path.length >= 3 ? path[path.length - 1] : path[1];
    const { data, setData, loadData, isLoading } = useTable({ url, params, onFetch, onLoadData });
    const { pagination, paging } = usePagination({ data, onChangePagination });
    const dataSource = React.useMemo(() => data?.results ?? [], [data]);
    const ObjToChangeKey = useMemo(() => {
      if (Array.isArray(columns)) {
        return Object.fromEntries(columns.map((el) => [el.dataIndex, el.title]));
      } else {
        return false;
      }
    }, [columns]);
    React.useImperativeHandle(ref, () => ({
      data,
      setData,
      loadData,
      isLoading,
      dataSource,
    }));

    return (
      <Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
        <CommTableHeader
          toggleObj={toggleObj}
          paging={paging}
          onAdd={onAdd}
          rightBts={rightBts}
          excel={excel}
          dataSource={dataSource}
          title={title}
          ObjToChangeKey={ObjToChangeKey}
        />
        {renderHeader}
        <Table
          dataSource={dataSource}
          columns={typeof columns === 'function' ? columns(data ?? {}) : columns}
          scroll={scroll}
          pagination={customPagination ?? pagination}
          loading={isLoading}
          {...args}
        />
      </Space>
    );
  },
);

export default CommTable;
