import React, { useEffect, useState } from 'react';
import { Button, Space, Descriptions, Spin, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';
import formatComma from '#utils/formatComma';
import useGetPartners from '../../../hooks/useGetPartners';

const cx = classNames.bind(styles);

function PartnersStoreAccountInfoIndex() {
  const { map } = useReduxStore('codes');
  const navigate = useNavigate();
  const { storeId } = useParams();
  const [partner, setPartner] = React.useState(null);
  const [partnerCode, setPartnerCode] = useState();
  const { partners } = useGetPartners();

  React.useEffect(() => {
    restApi
      .get(`/md/stores/${storeId}`)
      .then((response) => {
        setPartner(response.data);
      })
      .catch((e) => {
        Modal.warning({
          title: '실패',
          content: '정보를 받아오는 데 실패했습니다',
          onOk: () => {
            navigate('/manage/account', {
              replace: true,
            });
          },
          onCancel: () => {
            navigate('/manage/account', {
              replace: true,
            });
          },
        });
        console.warn(e);
      });
  }, []);

  useEffect(() => {
    if (partners && partner) {
      for (let i = 0; i < partners.length; i++) {
        if (partners[i].label == partner.storeName) {
          setPartnerCode(partners[i].value);
          break;
        }
      }
    }
  }, [partners, partner]);

  if (!partner) return <Spin />;
  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="파트너사아이디">
            {partner.storeUserId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="파트너사이름">
            {partner.storeName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="휴대전화">
            {partner.phone}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="이메일">
            {partner.storeEmail}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="판매상품">
            {partner.productCount && (
              <>
                {partner.productCount} 건{' '}
                <Button
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    navigate(`/manage/goods-info?dateType=CREATED_AT&endAt&page=0&startAt&storeId=${partnerCode}`);
                  }}
                >
                  판매상품 목록
                </Button>
              </>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="정산금액">
            {/* {typeof partner?.amount ? formatComma(partner.amount) : null} */}
            정산 가능 금액: {formatComma(partner.availableSettlementAmount)}원 / 정산 요청 금액:{' '}
            {formatComma(partner.requestSettlementAmount)}원
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="주문건수">
            신규 : {partner.newOrderCount}건 / 최근 : {partner.recentOrderCount}건
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="대표자명">
            {partner.businessInfo.representative}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="상호명">
            {partner.businessInfo.businessName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="통신판매업자번호">
            {partner.businessInfo.orderDealerNumber}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="사업자번호">
            {partner.businessInfo.businessNumber}
          </Descriptions.Item>
          <Descriptions.Item span={2} labelStyle={{ width: 160 }} label="사업자 주소">
            {`${partner.businessInfo.bizAddress || '-'} ${partner.businessInfo.addrDetail || '-'}`}
          </Descriptions.Item>
          <Descriptions.Item span={1} labelStyle={{ width: 160 }} label="우편번호">
            {partner.businessInfo.areacode || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 160 }} label="계좌번호">
            {map[partner.businessInfo.bankName]} {partner.businessInfo.accountNumber} /{' '}
            {partner.businessInfo.accountHolder}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/manage/account')}>목록</Button>
      </div>
    </div>
  );
}

export default PartnersStoreAccountInfoIndex;
