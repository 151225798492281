import React, { useState, useEffect } from 'react';
import { Button, Space, Descriptions, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from '../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';

const cx = classNames.bind(styles);

function MdMyInfoIndex() {
  const [myInfo, setMyInfo] = useState({});

  const { map } = useReduxStore('codes');
  const navigate = useNavigate();
  useEffect(() => {
    restApi.get('/md/accounts/my').then(({ data }) => setMyInfo(data));
  }, []);

  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD아이디">
            {myInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD이름">
            {myInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            {myInfo?.phone?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            {myInfo?.email}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140, verticalAlign: 'middle' }} label="신분증">
            <Image width={100} src={myInfo?.identificationFile} />
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="계좌번호">
            {myInfo?.bankCode && myInfo?.accountHolder
              ? `${map[myInfo.bankCode]} ${myInfo.accountNumber} / ${myInfo.accountHolder}`
              : null}
          </Descriptions.Item>
        </Descriptions>
      </Space>
      <div className={cx({ buttonBox: true })}>
        <Button onClick={() => navigate('/myInfo/modify')}>수정</Button>
      </div>
    </div>
  );
}

export default MdMyInfoIndex;
