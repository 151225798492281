import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import CommTable from '#components/table/CommTable';

function GoodsDeliveryFee() {
  const tableRef = React.useRef();

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <CommTable
        ref={tableRef}
        url="/md/stores"
        columns={[
          {
            title: '파트너ID',
            dataIndex: 'storeUserId',
            width: 150,
            render: (value) => (value ? value : '-'),
          },
          {
            title: '파트너이름',
            dataIndex: 'storeName',
            align: 'center',
            render: (value, obj) => <Link to={`/manage/account/detail/${obj.id}`}>{value || '-'}</Link>,
          },
          {
            title: '전화번호',
            dataIndex: 'phone',
            width: 300,
            render: (value) => {
              let renderValue = [...value.split('')];
              if (value.length == 11) {
                renderValue.splice(3, 0, '-');
                renderValue.splice(8, 0, '-');
              } else {
                renderValue.splice(3, 0, '-');
                renderValue.splice(7, 0, '-');
              }
              return renderValue.join('');
            },
          },
          {
            title: '이메일',
            dataIndex: 'storeEmail',
            width: 300,
            render: (value) => (value ? value : '-'),
          },
        ]}
        scroll={{ x: 1100 }}
        rowKey={({ id }) => id}
      />
    </Space>
  );
}

export default GoodsDeliveryFee;
