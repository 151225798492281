import React from 'react';
import { Divider, Row, Col, Space, DatePicker, Select, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import moment from 'moment';
import produce from 'immer';
import useIndex from '#hooks/useIndex';
import { useReduxStore } from '#hooks/useReduxStore';
import useGetPartners from '../../../hooks/useGetPartners';
import useCheckSearchTypeAndKeyword from '../../../hooks/useCheckSearchTypeAndKeyword';

const DATE_TYPE = {
  ANSWER_AT: 'AnswerAt',
  CREATED_AT: 'CreatedAt',
};

function SalesRequestEstimate() {
  const { getIndex } = useIndex();
  const { tree: codeTree, map: codeMap } = useReduxStore('codes');
  const { partners } = useGetPartners(); // 파트너명 불러오기

  const initailParams = React.useMemo(
    () => ({
      dateType: 'CreatedAt',
      startAt: null,
      endAt: null,
      answerStateCode: null,
      searchType: null,
      keyword: null,
    }),
    [],
  );

  const [params, setParams] = React.useState(initailParams);
  const [onClickSubmit] = useCheckSearchTypeAndKeyword(params.searchType, params.keyword);
  return (
    <div>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initailParams);
          return initailParams;
        }}
      >
        <Row gutter={[50, 15]}>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                value={params.dateType}
                placeholder="날짜 옵션"
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.dateType = value;
                    }),
                  );
                }}
                options={[
                  { label: '등록일', value: DATE_TYPE.CREATED_AT },
                  { label: '답변일', value: DATE_TYPE.ANSWER_AT },
                ]}
                style={{ width: 150 }}
              />
              <DatePicker.RangePicker
                // disabled={!params.dateType}
                value={[params.startAt ? moment(params.startAt) : null, params.endAt ? moment(params.endAt) : null]}
                onChange={(e) => {
                  if (e) {
                    const [startAt, endAt] = e;
                    setParams(
                      produce((draft) => {
                        draft.startAt = startAt.format('YYYY-MM-DD');
                        draft.endAt = endAt.format('YYYY-MM-DD');
                      }),
                    );
                  } else {
                    setParams(
                      produce((draft) => {
                        draft.startAt = null;
                        draft.endAt = null;
                      }),
                    );
                  }
                }}
              />
              <Select
                placeholder="답변여부"
                style={{ width: 120 }}
                value={params.answerStateCode}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.answerStateCode = value;
                    }),
                  );
                }}
                allowClear
                options={codeTree?.AS.items.map((value) => ({ label: value.label, value: value.code })) || []}
              />
            </Space>
          </Col>
          <Col span={24}>
            <Space style={{ width: '100%' }}>
              <Select
                placeholder="파트너명"
                style={{ width: 120 }}
                value={params.storeId}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.storeId = value;
                    }),
                  );
                }}
                allowClear
                options={partners || []}
              />
              <Select
                placeholder="검색 옵션"
                style={{ width: 120 }}
                value={params.searchType}
                onChange={(value) => {
                  setParams(
                    produce((draft) => {
                      draft.searchType = value;
                    }),
                  );
                }}
                allowClear
                options={[
                  { label: '내용', value: 'Contents' },
                  { label: '작성자', value: 'UserName' },
                  { label: '회원 아이디', value: 'UserId' },
                ]}
              />
              <Input
                placeholder="키워드를 입력하세요"
                style={{ width: 300 }}
                value={params.keyword}
                allowClear
                onChange={(event) => {
                  setParams(
                    produce((draft) => {
                      draft.keyword = event.target.value;
                    }),
                  );
                }}
              />
              <Space>
                <Button onClick={onClickSubmit} type="primary" htmlType="submit">
                  검색
                </Button>
                <Button htmlType="reset">초기화</Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <Divider />
      <CommTable
        url="/md/product-estimates"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            key: 'tableIndex',
            fixed: 'left',
            width: 50,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '파트너명',
            dataIndex: 'storeName',
            key: 'storeName',
            width: 100,
            render: (storeName) => storeName,
          },
          {
            title: '상품명',
            dataIndex: 'productName',
            key: 'productName',
            width: 100,
            render: (productName) => productName,
          },
          {
            title: '의뢰내용',
            dataIndex: 'contents',
            key: 'contents',
            width: 300,
            render: (contents, obj) => <Link to={`/manage/request_estimate/${obj.id}`}>{contents}</Link>,
          },
          {
            title: '회원아이디',
            dataIndex: 'userId',
            key: 'userId',
            width: 100,
            render: (userId) => userId,
          },
          {
            title: '회원이름',
            dataIndex: 'userName',
            key: 'userName',
            width: 100,
            render: (userName) => userName,
          },
          {
            title: '등록일',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (createdAt) => (createdAt ? moment(createdAt).format('YYYY-MM-DD HH:mm') : '-'),
          },
          {
            title: '답변여부',
            dataIndex: 'answerStateCode',
            key: 'answerStateCode',
            width: 100,
            render: (answerStateCode) => codeMap?.[answerStateCode],
          },
          {
            title: '답변일',
            dataIndex: 'answerAt',
            key: 'answerAt',
            width: 100,
            render: (answerAt) => (answerAt ? moment(answerAt).format('YYYY-MM-DD HH:mm') : '-'),
          },
        ]}
        scroll={{ x: 1120 }}
      />
    </div>
  );
}

export default SalesRequestEstimate;
