import React, { useMemo } from 'react';
import { Button, Space, Descriptions, Spin, Input, Select, Modal, Statistic } from 'antd';
import classNames from 'classnames/bind';
import styles from '../Page.module.less';
import { restApi } from '#apis';
import { useReduxStore } from '#hooks/useReduxStore';
import produce from 'immer';
import RegexObj from '#utils/regex';
import RequiredOrNotLabel from '#components/RequiredOrNotLabel';
import FileUpload from '#components/datalist/FileUpload';
import { useNavigate } from 'react-router-dom';
import handleError from '#utils/handleError';
import useSms from '#hooks/useSms';
import { restApiNotUsingToken } from '#apis';
import { useRef } from 'react';

const cx = classNames.bind(styles);
const { Countdown } = Statistic;

const REQUESTBODY_KEYS = {
  PHONE: 'phone',
  EMAIL: 'email',
  BANKNAME: 'bankCode',
  ACCOUNT_HOLDER: 'accountHolder',
  ACCOUNT_NUMBER: 'accountNumber',
  IDENTIFICATION_IMG_ID: 'identificationFile',
  UID: 'uuid',
};

function MdMyInfoModify() {
  const {
    tree: {
      PAAB: { items: bankItems },
    },
  } = useReduxStore('codes');
  const { verify, check } = useSms();
  const navigate = useNavigate();

  // // 핸드폰 인증
  const [timer, setTimer] = React.useState(null);
  const [authNum, setAuthNum] = React.useState('');

  const [myInfo, setMyInfo] = React.useState({});
  const [phoneNum, setPhoneNum] = React.useState([]);

  const phoneRef = useRef([]); // 휴대전화 input ref

  const [changing, setChanging] = React.useState(false);

  const [defaultShouldDuplicateKeys, setDefaultShouldDuplicateKeys] = React.useState({});

  const VALIDATION_INFO_OBJ = React.useMemo(
    () => ({
      [REQUESTBODY_KEYS.PHONE]: { label: '전화번호', regex: RegexObj.tel },
      [REQUESTBODY_KEYS.EMAIL]: { label: '이메일', regex: RegexObj.email },
      [REQUESTBODY_KEYS.BANKNAME]: { label: '은행명', required: true },
      [REQUESTBODY_KEYS.ACCOUNT_HOLDER]: { label: '예금주', required: true },
      [REQUESTBODY_KEYS.ACCOUNT_NUMBER]: { label: '계좌번호', required: true },
      [REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID]: { label: '신분증', required: true },
      [REQUESTBODY_KEYS.UID]: { label: '핸드폰 인증' },
    }),
    [],
  );

  const hanJongsung = (str) => {
    const lastChar = str.charAt(str.length - 1); // 마지막 글자 추출
    const lastCharCode = lastChar.charCodeAt(0); // 유니코드 값 구하기
    const jongSung = (lastCharCode - 0xac00) % 28; // 받침 유무 판단

    return jongSung !== 0; // 받침이 있으면 true, 없으면 false 반환
  };

  const checkValidation = React.useCallback(
    (_myInfo) => {
      const checkList = Object.values(REQUESTBODY_KEYS);

      for (const key of checkList) {
        let ObjectMarker = hanJongsung(VALIDATION_INFO_OBJ[key].label) ? '을' : '를';

        if (VALIDATION_INFO_OBJ[key].required && !_myInfo[key]) {
          if (VALIDATION_INFO_OBJ[key].label === '신분증') {
            Modal.warning({
              content: `${VALIDATION_INFO_OBJ[key].label}${ObjectMarker} 등록해주세요.`,
            });
            return;
          }
          Modal.warning({
            content: `${VALIDATION_INFO_OBJ[key].label}${ObjectMarker} 입력해주세요.`,
          });
          return;
        }
        if (
          VALIDATION_INFO_OBJ[key].regex &&
          _myInfo[key] !== null &&
          !VALIDATION_INFO_OBJ[key].regex.test(_myInfo[key])
        ) {
          Modal.warning({
            content: `알맞은 ${VALIDATION_INFO_OBJ[key].label}${ObjectMarker} 입력해주세요.`,
          });
          return;
        }
      }

      if (myInfo[REQUESTBODY_KEYS.PHONE] !== defaultShouldDuplicateKeys[REQUESTBODY_KEYS.PHONE]) {
        if (!myInfo[REQUESTBODY_KEYS.UID]) {
          Modal.warning({
            centered: true,
            content: '핸드폰 인증을 완료해주세요.',
            okText: '확인',
          });
          return;
        }
      }
      return true;
    },
    [VALIDATION_INFO_OBJ, defaultShouldDuplicateKeys, myInfo],
  );

  const handleSave = async () => {
    if (changing) return;
    try {
      if (checkValidation(myInfo)) {
        setChanging(true);
        const myInfoObjToSave = { ...myInfo };

        await restApi.put('md/accounts/my', myInfoObjToSave);

        Modal.success({
          content: '수정을 완료했습니다.',
          onOk: () => {
            navigate('/myInfo');
          },
        });

        setChanging(false);
      }
    } catch (e) {
      console.log(e);
      Modal.warning({
        centered: true,
        content: '수정하는데 실패했습니다. \n 관리자에게 문의해주세요.',
      });
      setChanging(false);
      handleError(e);
    }
  };

  const askCancel = React.useCallback(() => {
    let myInfo_ = { ...myInfo };
    const isChangedDefaultValue = (obj1, obj2) => {
      const commonKeys = Object.keys(obj1).filter((key) => obj2.hasOwnProperty(key));

      const resultObj1 = {};
      const resultObj2 = {};

      commonKeys.forEach((key) => {
        resultObj1[key] = obj1[key];
        resultObj2[key] = obj2[key];
      });
      const resultStr1 = JSON.stringify(resultObj1);
      const resultStr2 = JSON.stringify(resultObj2);

      return resultStr1 !== resultStr2;
    };

    if (isChangedDefaultValue(myInfo_, defaultShouldDuplicateKeys)) {
      Modal.confirm({
        centered: true,
        onOk: () => {
          navigate('/myInfo');
        },
        content: '입력했던 내용이 모두 사라집니다. \n계속 하시겠습니까? ',
        okText: '확인',
        cancelText: '취소',
      });
    } else {
      navigate('/myInfo');
    }
  }, [navigate, myInfo, defaultShouldDuplicateKeys]);

  const askAuthNumber = () => {
    if (!RegexObj.phone.test(myInfo[REQUESTBODY_KEYS.PHONE])) {
      alert('알맞은 핸드폰 번호를 입력해주세요');
      return;
    }

    restApiNotUsingToken
      .get(`/md/accounts/check/phone`, {
        params: {
          phone: myInfo[REQUESTBODY_KEYS.PHONE],
        },
      })
      .then(async () => {
        await verify(myInfo[REQUESTBODY_KEYS.PHONE]);
        setAuthNum('');
        setMyInfo(
          produce((draft) => {
            draft[REQUESTBODY_KEYS.UID] = null;
          }),
        );
        setTimer(Date.now() + 180 * 1000);
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          Modal.warning({ content: '이미 가입된 아이디가 존재합니다.', centered: true, okText: '확인' });
          return;
        }

        if (e?.response?.status === 422) {
          Modal.warning({
            content: '알맞은 핸드폰 번호를 입력해주세요.',
            centered: true,
            okText: '확인',
          });
          return;
        }

        Modal.warning({
          content: '인증번호 전송에 실패했습니다. 잠시후 시도해주세요.',
          centered: true,
          okText: '확인',
        });
      });
  };

  const handleAuth = () => {
    check({ code: authNum })
      .then((uuid) => {
        setMyInfo(
          produce((draft) => {
            draft[REQUESTBODY_KEYS.UID] = uuid;
          }),
        );
        setTimer(null);
      })
      .catch((error) => {
        Modal.error({
          title: '인증 실패',
          content: error?.response?.data?.message || '인증에 실패했습니다.',
          centered: true,
          okText: '확인',
        });
      });
  };

  React.useEffect(() => {
    restApi.get('/md/accounts/my').then(({ data }) => {
      const { identificationFile, phone, ...rest } = data;
      const newMyInfoObj = rest;
      // myInfo 초기화---
      newMyInfoObj[REQUESTBODY_KEYS.PHONE] = phone;

      if (identificationFile) {
        // 신분증 id 값만 추출
        const parsedIdentificationImage = +identificationFile.split('/').reverse()[0];
        newMyInfoObj[REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID] = parsedIdentificationImage;
      }

      setMyInfo(newMyInfoObj);
      //----------------

      let phone_ = newMyInfoObj[REQUESTBODY_KEYS.PHONE];
      let formatedPhone = [];

      if (/^01\d{1}-?\d{3,4}-?\d{4}$/.test(phone_)) {
        phone_ = phone_.replace(/-/g, '');

        if (phone_.length === 10) {
          formatedPhone = [phone_.slice(0, 3), phone_.slice(3, 6), phone_.slice(6)];
        } else if (phone_.length === 11) {
          formatedPhone = [phone_.slice(0, 3), phone_.slice(3, 7), phone_.slice(7)];
        }
        setPhoneNum(formatedPhone);
      }

      setDefaultShouldDuplicateKeys({
        [REQUESTBODY_KEYS.PHONE]: newMyInfoObj[REQUESTBODY_KEYS.PHONE],
        [REQUESTBODY_KEYS.EMAIL]: newMyInfoObj[REQUESTBODY_KEYS.EMAIL],
        [REQUESTBODY_KEYS.ACCOUNT_HOLDER]: newMyInfoObj[REQUESTBODY_KEYS.ACCOUNT_HOLDER],
        [REQUESTBODY_KEYS.ACCOUNT_NUMBER]: newMyInfoObj[REQUESTBODY_KEYS.ACCOUNT_NUMBER],
        [REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID]: newMyInfoObj[REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID],
        [REQUESTBODY_KEYS.BANKNAME]: newMyInfoObj[REQUESTBODY_KEYS.BANKNAME],
      });
    });
  }, []);

  //phoneNum state 변경 시 myInfo state 변경
  React.useEffect(() => {
    if (phoneNum.length !== 0) {
      setMyInfo(
        produce((draft) => {
          draft[REQUESTBODY_KEYS.PHONE] = phoneNum.join('');
          draft[REQUESTBODY_KEYS.UID] = null;
        }),
      );
    }
  }, [phoneNum]);

  const onChangeInput = (e) => {
    let maxLength = e.target.maxLength;
    let id = parseInt(e.target.id);
    let value = e.target.value;
    if (isNaN(value)) return;
    else {
      setPhoneNum(
        produce((draft) => {
          draft[id] = value;
        }),
      );
      if (id === 0 && value.length === maxLength) {
        phoneRef.current[id + 1].focus();
      } else if (value.length === maxLength) {
        phoneRef.current[id + 1].focus();
      }
    }
  };

  const onFocusInput = (e) => {
    let id = e.target.id;
    setPhoneNum(
      produce((draft) => {
        draft[id] = '';
      }),
    );
  };

  if (!myInfo) return <Spin />;
  return (
    <div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Descriptions bordered>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD아이디">
            {myInfo?.userId}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="MD이름">
            {myInfo?.userName}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="휴대전화">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                width: 370,
                alignItems: 'center',
              }}
            >
              <Input
                ref={(el) => (phoneRef.current[0] = el)}
                maxLength={3}
                id={0}
                style={{ width: 70, textAlign: 'center' }}
                disabled={myInfo[REQUESTBODY_KEYS.UID]}
                value={phoneNum[0]}
                onChange={onChangeInput}
                onFocus={onFocusInput}
              />
              <span>-</span>
              <Input
                ref={(el) => (phoneRef.current[1] = el)}
                maxLength={4}
                id={1}
                style={{ width: 70, textAlign: 'center' }}
                disabled={myInfo[REQUESTBODY_KEYS.UID]}
                value={phoneNum[1]}
                onChange={onChangeInput}
                onFocus={onFocusInput}
              />
              <span>-</span>
              <Input
                ref={(el) => (phoneRef.current[2] = el)}
                maxLength={4}
                id={2}
                style={{ width: 70, textAlign: 'center' }}
                disabled={myInfo[REQUESTBODY_KEYS.UID]}
                value={phoneNum[2]}
                onChange={onChangeInput}
                onFocus={onFocusInput}
              />
              <Button
                ref={(el) => (phoneRef.current[3] = el)}
                disabled={
                  myInfo[REQUESTBODY_KEYS.UID] ||
                  myInfo[REQUESTBODY_KEYS.PHONE] === defaultShouldDuplicateKeys[REQUESTBODY_KEYS.PHONE]
                }
                onClick={askAuthNumber}
              >
                {myInfo[REQUESTBODY_KEYS.UID] ? '휴대폰 인증 완료' : '인증 번호 전송'}
              </Button>
            </div>
            {!!timer && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <Input
                  onChange={(e) => {
                    setAuthNum(e.target.value);
                  }}
                  value={authNum}
                  style={{ width: 200, marginRight: 10 }}
                />
                <Button disabled={authNum?.length === 0} onClick={handleAuth}>
                  확인
                </Button>
                <div style={{ width: 10 }} />
                <Countdown
                  valueStyle={{ fontSize: 10 }}
                  value={timer}
                  onFinish={() => {
                    setTimer(null);
                  }}
                />
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item span={3} labelStyle={{ width: 140 }} label="이메일">
            <Input
              style={{ width: 200 }}
              value={myInfo[REQUESTBODY_KEYS.EMAIL]}
              onChange={(event) => {
                setMyInfo(
                  produce((draft) => {
                    draft[REQUESTBODY_KEYS.EMAIL] = event.target.value;
                  }),
                );
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140, verticalAlign: 'middle' }}
            label={<RequiredOrNotLabel label="신분증" required />}
          >
            <Space direction="vertical">
              <FileUpload
                listType="picture-card"
                items={myInfo?.[REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID] || []}
                onDoneChange={(fileListDone) => {
                  setMyInfo(
                    produce((draft) => {
                      draft[REQUESTBODY_KEYS.IDENTIFICATION_IMG_ID] = fileListDone[0]?.response[0]?.id;
                    }),
                  );
                }}
                hideUpload={({ length }) => length > 0}
                maxCount={1}
              />
            </Space>
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            labelStyle={{ width: 140, verticalAlign: 'middle' }}
            label={<RequiredOrNotLabel label="계좌번호" required />}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ width: '60px' }}>은행</label>
                  <Select
                    value={myInfo[REQUESTBODY_KEYS.BANKNAME]}
                    style={{ width: 200, marginRight: 30 }}
                    placeholder="은행 선택"
                    options={bankItems?.map((value) => ({ label: value.label, value: value.code })) || []}
                    onChange={(bankValue) =>
                      setMyInfo(
                        produce((draft) => {
                          draft[REQUESTBODY_KEYS.BANKNAME] = bankValue;
                        }),
                      )
                    }
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ width: '100px' }}>예금주</label>
                  <Input
                    value={myInfo[REQUESTBODY_KEYS.ACCOUNT_HOLDER]}
                    onChange={(event) => {
                      setMyInfo(
                        produce((draft) => {
                          draft[REQUESTBODY_KEYS.ACCOUNT_HOLDER] = event.target.value;
                        }),
                      );
                    }}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                <label style={{ width: '65px' }}>계좌번호</label>
                <Input
                  style={{ width: 200 }}
                  value={myInfo[REQUESTBODY_KEYS.ACCOUNT_NUMBER]}
                  placeholder="정확한 계좌번호를 입력해주세요"
                  onChange={(event) => {
                    if (isNaN(event.target.value)) return;
                    else {
                      setMyInfo(
                        produce((draft) => {
                          draft[REQUESTBODY_KEYS.ACCOUNT_NUMBER] = event.target.value;
                        }),
                      );
                    }
                  }}
                />
              </div>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Space>

      <div className={cx({ buttonBox: true })}>
        <Button onClick={askCancel}>취소</Button>
        <Button onClick={handleSave}>저장</Button>
      </div>
    </div>
  );
}

export default MdMyInfoModify;
