import { restApi } from '#apis/index';
import handleError from '#utils/handleError';
import produce from 'immer';
import React from 'react';
import { Button, Space, Select, Row, Col, Modal, Spin } from 'antd';
import SearchForm from '#components/SearchForm';
import CommTable from '#components/table/CommTable';
import useFetch from '#hooks/useFetch';
import useIndex from '#hooks/useIndex';
import AdvertiseHistories from '#components/advertise/AdvertiseHistories';
import useGetPartners from '#hooks/useGetPartners';

const AdFilterType = {
  all: 'ALL',
  on: 'ADON',
  off: 'ADOFF',
};

function Advertise() {
  const { getIndex } = useIndex();

  const initialParams = React.useMemo(
    () => ({
      adFilterType: AdFilterType.all,
      adStatusType: null,
      page: 0,
      storeId: null,
    }),
    [],
  );
  const [params, setParams] = React.useState(initialParams);
  const tableRef = React.useRef();
  const areaCodes = useFetch();
  const areaCodes2 = useFetch();
  const [categories, setCategories] = React.useState({});

  const { partners } = useGetPartners();

  React.useEffect(() => {
    restApi.get('/categories/product').then(({ data }) => {
      let categories_ = [];
      Object.keys(data.tree).forEach((key) => {
        const element = data.tree[key];
        const category = { value: element.code, label: element.label };
        categories_.push(category);
      });
      data['options'] = categories_;
      setCategories(data);
    });
  }, []);

  React.useEffect(() => {
    areaCodes.fetch(restApi.get('/area-codes/filter')).catch(handleError);
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <SearchForm
        params={params}
        onInit={setParams}
        onReset={() => {
          setParams(initialParams);
          areaCodes2.clear();
          return initialParams;
        }}
      >
        <Row gutter={10} style={{ marginBottom: 10, alignItems: 'center', rowGap: 10 }}>
          <Col>
            <Select
              value={params.storeId}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={(value) =>
                setParams(
                  produce((draft) => {
                    draft.storeId = value;
                  }),
                )
              }
              options={partners}
              style={{ width: 150 }}
              placeholder="파트너명"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra1}
              loading={areaCodes.loading || areaCodes2.loading}
              onChange={async (value) => {
                await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
                setParams(
                  produce((draft) => {
                    draft.extra1 = value;
                    draft.extra2 = null;
                    draft.page = 0;
                  }),
                );
              }}
              options={areaCodes.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 150 }}
              disabled={!(areaCodes.data?.length > 0)}
              placeholder="시/도"
              allowClear
            />
          </Col>
          <Col>
            <Select
              value={params.extra2}
              loading={areaCodes2.loading}
              onChange={(value) => {
                setParams(
                  produce((draft) => {
                    draft.extra2 = value;
                    draft.page = 0;
                  }),
                );
              }}
              options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
              style={{ width: 150 }}
              disabled={!(areaCodes2.data?.length > 0)}
              placeholder="시/군/구"
              allowClear
            />
          </Col>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                검색
              </Button>
              <Button htmlType="reset">초기화</Button>
            </Space>
          </Col>
        </Row>
      </SearchForm>
      <CommTable
        ref={tableRef}
        url="/md/advertisement/area"
        columns={[
          {
            title: '번호',
            dataIndex: 'tableIndex',
            index: 'tableIndex',
            fixed: true,
            width: 30,
            render: (_, __, index) => getIndex(index),
          },
          {
            title: '파트너명',
            dataIndex: 'storeId',
            index: 'storeId',
            fixed: true,
            width: 50,
            render: (storeId) => partners.find((element) => element.value === storeId.toString())?.label,
          },
          {
            title: '시도',
            dataIndex: 'extra1',
            width: 50,
            render: (value) => value,
          },
          {
            title: '시군구',
            dataIndex: 'extra2',
            width: 50,
            render: (value) => value,
          },
          {
            title: '광고 수(진행중/전체)',
            dataIndex: 'advertiseNumber',
            width: 50,
            render: (_, row) => `${row.adCount ?? 0}/${row.totalCount ?? 0}`,
          },
          {
            title: '광고비(1일기준)',
            dataIndex: 'price',
            width: 50,
            render: (price) => `${(price ?? 0).toLocaleString()}원`,
          },

          {
            title: '',
            dataIndex: 'code',
            width: 30,
            render: (_, data) =>
              // categoreis 세팅되기 전까지 로딩 애니메이션 제공
              Object.keys(categories).length ? (
                <Button
                  onClick={() => {
                    Modal.info({
                      title: `광고 내역 > ${partners.find((element) => element.value === String(data.storeId))?.label}`,
                      width: 1200,
                      content: (
                        <AdvertiseHistories
                          areaCode={data.code}
                          storeId={String(data.storeId)}
                          categories={categories}
                          areaGroup={{ area1: data.extra1, area2: data.extra2 }}
                        />
                      ),

                      centered: true,
                      closable: true,
                    });
                  }}
                  type="ghost"
                >
                  광고 내역
                </Button>
              ) : (
                <Spin size="small" />
              ),
          },
        ]}
        scroll={{ x: 1200 }}
      />
    </Space>
  );
}

export default Advertise;
